import React from "react"
import styled from "@emotion/styled"
import { Container } from "./Container"
import { useStaticQuery, graphql } from "gatsby"
import { mediaQueries } from "./breakpoints"
import { css } from "@emotion/react"

const Footer = styled("footer")`
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: var(--black);
  ${mediaQueries.smallDown(
    css`
      padding: 1.5rem 0 2rem;
    `
  )}
`

const FooterLinks = styled("ul")`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  margin: 0;
  padding: .5rem 0;
`

const FooterLink = styled("li")`
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  a {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem;
    white-space: nowrap;
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "settings" } }) {
        frontmatter {
          contactEmail
          contactPhone
        }
      }
    }
  `)

  return (
    <Footer id="kontakt">
      <Container>
        <FooterLinks>
          <FooterLink>
            <strong><a target="_blank" rel="noopener noreferrer" href="https://inlist.cz">Institut liberálních studií</a> {new Date().getFullYear()}</strong>
          </FooterLink>
          <FooterLink>
            <strong>Telefon: <a href={"tel:" + data.markdownRemark.frontmatter.contactPhone}>{data.markdownRemark.frontmatter.contactPhone}</a></strong>
          </FooterLink>
          <FooterLink>
            <strong>E-mail: <a href={"mailto:" + data.markdownRemark.frontmatter.contactEmail}>{data.markdownRemark.frontmatter.contactEmail}</a></strong>
          </FooterLink>
        </FooterLinks>
      </Container>
    </Footer>
  )
}
